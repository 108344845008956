.fileManagement {
	padding: 0;

	.filter {
		padding-top: 0.25rem;
		padding-left: 1rem;
		padding-right: 1rem;
		background-color: #e9e9e9;

		.orgSelector {
			margin-top: 0rem;
		}

		.serviceSelector {
			margin-top: 0rem;
		}

		.projSelector {
			margin-top: 0rem;
		}

		.MuiFormControl-root {
			margin-top: 0.1rem;
		}
	}

	.buttons {
		display: flex;
		padding: 0.4rem 1rem;
	}
}