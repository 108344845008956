.uploadDelivery {
	padding-top: 1rem;
	.card {
		padding-bottom: 1rem;

		.uploadFileSelector {
			margin-top: 1rem;
		}

		.errorMessage {
			color: #bd0100;
			white-space: pre-line;
		}

		.openDateChk {
			margin-top: 1rem;
		}

		.mailSendChk {
			margin-top: 1rem;
		}

		.uploadFileBtn {
			width: calc(50% - 1rem);
		}

		.cancelBtn {
			width: calc(50% - 1rem);
			margin-left: 1rem;
		}

		.divider {
			margin-top: 2rem;
			margin-bottom: 2rem;
		}
	}
}