body {
	height: 100%;
	margin: 0;
	padding: 0;
}

.app {
	height: 100%;

	.app-nabvar {
		overflow: hidden;
		position: relative;
		background: linear-gradient(to right, rgb(37, 0, 86), rgb(0, 118, 174));

		a {
			color: white;
			font-size: 0.9rem;
			font-weight: 400;
		}

		a.active {
			color: skyblue;
		}

		.app-nabvar-container {
			z-index: 1;
		}

		.navbarbackground {
			z-index: 0;
			position: absolute;
			right: 0;
			transform: scale(2);
		}

		.logo {
			margin-right: 0.5rem;
		}

		.changeLanguage {
			border: 0;
			color: white;
		}
	}

	.card {
		box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
		padding-bottom: 0.5rem;
	}
}

.block{
	display: none;
	width: 100%;
	height: 100%;
	z-index: 10000;
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(255, 255, 255, 0.5);
}

.changeLanguageLang {
	margin-left: 32px;
}
